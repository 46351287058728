// 
// root.scss
//

@mixin color-mode($mode: light, $root: false) {
    @if $color-mode-type =="media-query" {
        @if $root ==true {
            @media (prefers-color-scheme: $mode) {
                :root {
                    @content;
                }
            }
        }

        @else {
            @media (prefers-color-scheme: $mode) {
                @content;
            }
        }
    }

    @else {
        [data-bs-theme="#{$mode}"] {
            @content;
        }
    }
}

//theme-light
:root,
[data-bs-theme="light"] {
    --#{$prefix}header-bg: #{$header-bg};
    --#{$prefix}header-item-color: #{$header-item-color};
    --#{$prefix}header-item-sub-color: #{$text-muted};

    --#{$prefix}header-dark-bg: #003032;


    // vetical sidebar
    --#{$prefix}sidebar-bg: #ffffff;
    --#{$prefix}sidebar-menu-item-color: #7b8190;
    --#{$prefix}sidebar-menu-sub-item-color: #7b8190;
    --#{$prefix}sidebar-menu-item-icon-color: #7b8190;
    --#{$prefix}sidebar-menu-item-hover-color: #383c40;
    --#{$prefix}sidebar-menu-item-active-color: #3980c0;
    --#{$prefix}sidebar-menu-sub-item-active-color: #383c40;
    --#{$prefix}sidebar-border-color: #e9ebef;
    --#{$prefix}sidebar-menu-item-active-bg-color: #f2f3fe;


    // Vertical Sidebar - Dark
    [data-sidebar="dark"] {
        --#{$prefix}sidebar-dark-bg: #061e2c;
        --#{$prefix}sidebar-dark-menu-item-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-sub-item-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-item-icon-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-item-hover-color: #d6e0fb;
        --#{$prefix}sidebar-dark-menu-item-active-color: #{$white};
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #003f41;
        --#{$prefix}sidebar-menu-item-active-bg-color: #003f41;
    }


    // Topbar Search
    // --#{$prefix}topbar-search-bg: #f3f3f9;
    --#{$prefix}topbar-dark-search-bg: #363a38;
    --#{$prefix}search-width: 0px;
    --#{$prefix}search-border-color: #e9e9ef;
    --#{$prefix}box-shadow: 0 2px 3px #e6e8eb;
    --#{$prefix}boxed-body-bg: #ecedef;
    --#{$prefix}input-bg: #ffffff;

    // Topbar User
    --#{$prefix}topbar-search-bg: transparent;

    // Horizontal nav
    --#{$prefix}topnav-bg: #fff;
    --#{$prefix}topnav-item-color: #{$gray-400};
    --#{$prefix}menu-item-color: #7b8190;
    --#{$prefix}topnav-item-color-active: #{$white};
    --#{$prefix}menu-item-color: #7b8190;
    --#{$prefix}menu-item-active-color: #3980c0;

    [data-topbar="dark"] {
        --#{$prefix}header-dark-bg: #003032;
        --#{$prefix}header-dark-item-color: #e9ecef;
        --#{$prefix}header-item-sub-color: #8795ab;
    }

    [data-topbar="brand"] {
        --#{$prefix}header-bg: #{$blue-700};
        --#{$prefix}header-item-color: #{$gray-100};
        --#{$prefix}header-item-sub-color: #{lighten($blue-100, 1.5%)};
    }

    [data-sidebar="brand"]{
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #ffffff48;
    }

    // Footer
    --#{$prefix}footer-bg: #f2f2f5;
    --#{$prefix}footer-color: #74788d;

}



//theme dark
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        --#{$prefix}light: #{$light-dark};
        --#{$prefix}light-rgb: #{to-rgb($light-dark)};
        --#{$prefix}dark: #{$gray-200};
        --#{$prefix}dark-rgb: #{to-rgb($gray-200)};
        --#{$prefix}header-dark-bg: #{$header-bg-dark};
        --#{$prefix}header-dark-item-color: #{$header-item-color-dark};
        --#{$prefix}header-item-sub-color: #{$text-muted};

        // Vertical sidebar 
        --#{$prefix}sidebar-bg: #ffffff;
        --#{$prefix}sidebar-menu-item-color: #7b8190;
        --#{$prefix}sidebar-menu-sub-item-color: #7b8190;
        --#{$prefix}sidebar-menu-item-icon-color: #7b8190;
        --#{$prefix}sidebar-menu-item-hover-color: #383c40;
        --#{$prefix}sidebar-menu-item-active-color: #3980c0;
        --#{$prefix}sidebar-menu-sub-item-active-color: #383c40;
        --#{$prefix}sidebar-border-color: #e9ebef;
        --#{$prefix}sidebar-menu-item-active-bg-color: #003f41;

        // Vertical Sidebar - Dark
        --#{$prefix}sidebar-dark-bg: #061e2c;
        --#{$prefix}sidebar-dark-menu-item-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-sub-item-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-item-icon-color: #a8b0c5;
        --#{$prefix}sidebar-dark-menu-item-hover-color: #d6e0fb;
        --#{$prefix}sidebar-dark-menu-item-active-color: #{$white};
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #082739;

        &[data-sidebar="dark"]{
            --#{$prefix}sidebar-dark-bg: #061e2c;
            --#{$prefix}sidebar-dark-menu-item-color: #a8b0c5;
            --#{$prefix}sidebar-dark-menu-sub-item-color: #a8b0c5;
            --#{$prefix}sidebar-dark-menu-item-icon-color: #a8b0c5;
            --#{$prefix}sidebar-dark-menu-item-hover-color: #d6e0fb;
            --#{$prefix}sidebar-dark-menu-item-active-color: #{$white};
            --#{$prefix}sidebar-dark-menu-item-active-bg-color: #082739;
        }

        &[data-sidebar="brand"]{
        --#{$prefix}sidebar-dark-menu-item-active-bg-color: #ffffff73;
        }

        // // Topbar Search 
        --#{$prefix}topbar-search-bg: transperent;
        --#{$prefix}search-width: 1px;
        --#{$prefix}search-border-color: #3b403d;
        --#{$prefix}menu-item-color: #a8b0c5;

        .table-light {
            --#{$prefix}table-color:                       #{lighten($light-dark, 100%)};
            --#{$prefix}table-bg:                          var(--#{$prefix}light);
            --#{$prefix}table-border-color:                var(--#{$prefix}border-color);
            --#{$prefix}table-striped-bg:                  var(--#{$prefix}light);
            --#{$prefix}table-striped-color:               #{lighten($light-dark, 100%)};
            --#{$prefix}table-active-bg:                   var(--#{$prefix}light);
            --#{$prefix}table-active-color:                #{lighten($light-dark, 100%)};
            --#{$prefix}table-hover-bg:                    var(--#{$prefix}light);
            --#{$prefix}table-hover-color:                 #{lighten($light-dark, 100%)};
        }

        // footer
        --#{$prefix}footer-bg: #{$gray-700};
        --#{$prefix}footer-color: #adb5bd;
        --#{$prefix}footer-border-color: #{$border-color-dark};

        //Horizontal 
        --#{$prefix}topnav-bg: #072435;
        --#{$prefix}topnav-item-color: #{$gray-400};
        --#{$prefix}topnav-item-color-active: #{$white};
        --#{$prefix}menu-item-color: #a8b0c5;
        --#{$prefix}menu-item-active-color: #ffffff;

        --#{$prefix}box-shadow: 0 2px 3px #04121a;
        --#{$prefix}boxed-body-bg: #082a3e;
        --#{$prefix}input-bg: #072435;
    }
}