//
// plugins-rtl.scss
//

// lightbox

.modal-video-close-btn{
    left: 0px;
    right: auto;
    @media (orientation: landscape) {
        right: auto;
        left: -45px;
    }
}

// rdw-editor

.rdw-dropdown-carettoclose, .rdw-dropdown-carettoopen{
    right: auto;
    left: 10%;
}

// form wizard

.twitter-bs-wizard {
    .twitter-bs-wizard-nav {
        .nav-link::after{
            left: auto;
            right: 62%;
        }
    }

    .twitter-bs-wizard-pager-link li.next{
        float: left;
    }
}


// Wizard

.wizard-nav {
    .list-item{
        &:after{
            right: 62%; 
            left: auto;
        }
    }
}


// Range-slider


.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
    right: 0;
    left: auto;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
    right: -17px;
    left: auto;
}